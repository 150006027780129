<template>
    <div>
        <div class="row data-form">
            <div class="col-12 col-md-12">
                <ValidationObserver ref="form">
                <ValidationProvider name="status" rules="required" v-slot="{valid, errors}">
                    <b-form-group :label="$t('status')">
                        <multi-selectbox :multiple="false"
                                         :validate-error="errors[0]"
                                         :options="statusOptions"
                                         v-model="form.status">
                        </multi-selectbox>
                    </b-form-group>
                </ValidationProvider>
                    <div v-if="form.status==='declined'">
                        <ValidationProvider name="reason" rules="required" v-slot="{valid, errors}">
                            <b-form-group :label="$t('reason')">
                                <b-form-textarea v-model="form.reason"
                                                 :state="errors[0] ? false : null">
                                </b-form-textarea>
                                <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]"></b-form-invalid-feedback>
                            </b-form-group>
                        </ValidationProvider>
                    </div>

                </ValidationObserver>
            </div>
            <div class="col-12 col-md-12 d-flex justify-content-between">
                <b-button variant="primary" :active="activeButton" @click="updateApproved">{{$t('save')}}</b-button>
            </div>
        </div>
    </div>
</template>
<script>
import {ValidationObserver, ValidationProvider} from "vee-validate";
import ThesisAndProjectService from "@/services/ThesisAndProjectService";

export default {
    components: {
        ValidationProvider,
        ValidationObserver,
    },
    props:{
      formId:{
          type:Number
      }
    },
    data() {
        return {
            activeButton:false,
            form:{},
            statusOptions:[
                {
                    text:this.$t('approved'),
                    value:'approved'
                },
                {
                    text:this.$t('declined'),
                    value:'declined'
                }

            ]
        }
    },
    methods: {
      async  updateApproved(){
         let isValid = await this.$refs.form.validate()
            if(isValid){
                this.activeButton=true
                this.form.status==='declined'?this.form.reason:delete this.form.reason
                this.form.thesis_and_project_id=this.formId
            }
          this.$swal.fire({
              text: this.$t('are_you_sure_to_status_approved'),
              showCancelButton: true,
              confirmButtonText: this.$t('yes'),
              cancelButtonText: this.$t('no')
          })
              .then((result) => {
                  if (result.isConfirmed) {
                      ThesisAndProjectService.approvedStatus(this.form)
                          .then(response =>{

                              this.$toast.success(response.data.message)
                              this.$emit('successStatus',true)
                          }).catch(err=>{
                          this.showErrors(err)
                      }).finally(this.activeButton=false)
                  }
              })


        }
    }
};
</script>
